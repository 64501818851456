import { Box, Spinner, Center } from "@chakra-ui/react";
import CardSlide from "../../../components/Carousel";

import React, { useEffect } from "react";
import { getDailyStudent } from "../../../configs/redux/action";
import { connect } from "react-redux";

const DailyReport = ({ dataDailyStudent, getDailyStudent, loadingData }) => {
  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getDailyStudent();
    };
    parseUserInfo();
  }, [getDailyStudent]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loadingData ? (
        <Center mt="70px">
          <Spinner size="xl" />
        </Center>
      ) : (
        <CardSlide data={dataDailyStudent} />
      )}
    </Box>
  );
};

const reduxState = (state) => ({
  dataDailyStudent: state.getData.dataDailyStudent.data,
  loadingData: state.getData.dataDailyStudent.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getDailyStudent: () => dispatch(getDailyStudent()),
  };
};

export default connect(reduxState, reduxDispatch)(DailyReport);
