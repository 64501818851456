import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VisuallyHiddenInput,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import formatDate from "components/calendar/FormatDate";

const schema = yup
  .object({
    name: yup.string().required(),
    amount: yup.number().positive().integer().required(),
  })
  .required();

const HistoryModal = ({
  isOpen,
  handleCloseModal,
  selectedRow,
  handleSaveChanges,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    console.log(selectedRow);
    if (selectedRow) {
      if (Object.keys(selectedRow).length > 2) {
        setValue("payment_name", selectedRow.data);
        setValue("name", selectedRow.name);
        setValue("amount", selectedRow.amount);
        setValue("history_id", selectedRow.id);
        setValue("date_paid", formatDate(selectedRow.date_paid));
      } else {
        setValue("payment_name", selectedRow.data);
        setValue("name", "");
        setValue("amount", "");
        setValue("date_paid", null);
        setValue("history_id", "");
      }
    } else {
      setValue("payment_name", "");
      setValue("history_id", "");
      setValue("name", "");
      setValue("amount", "");
      setValue("date_paid", null);
    }
  }, [selectedRow, setValue]);

  const onSubmit = (data) => {
    let id = selectedRow?.[0] || 0;

    handleSaveChanges(id, data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>History Pembayaran</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel htmlFor="name">Nama Pembayaran</FormLabel>
              <Input
                type="text"
                name="payment_name"
                id="payment_name"
                {...register("payment_name")}
                isReadOnly
              />
            </FormControl>
            <VisuallyHiddenInput
              type="text"
              name="history_id"
              id="history_id"
              {...register("history_id")}
            />
            <FormControl mb={4}>
              <FormLabel htmlFor="name">Nama History Pembayaran</FormLabel>
              <Input type="text" name="name" id="name" {...register("name")} />
              {errors.name && (
                <Text fontSize="xs" color="tomato">
                  {errors.name.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="amount">Nominal</FormLabel>
              <Input
                type="number"
                name="amount"
                id="amount"
                {...register("amount")}
              />
              {errors.amount && (
                <Text fontSize="xs" color="tomato">
                  {errors.amount.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="date_paid">Tanggal Pembayaran</FormLabel>
              <Input
                type="date"
                name="date_paid"
                id="date_paid"
                {...register("date_paid")}
              />
              {errors.date_paid && (
                <Text fontSize="xs" color="tomato">
                  {errors.date_paid.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default HistoryModal;
