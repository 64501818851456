import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { getALLTeacher, getALLStudent } from "../../../configs/redux/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import formatDate from "components/calendar/FormatDate";

const schema = yup
  .object({
    teacher_id: yup.number().positive().integer().required(),
    student_id: yup.number().positive().integer().required(),
    date: yup.string().required(),
    activity: yup.string().required(),
    note: yup.string().required(),
  })
  .required();

const ClassModal = ({
  isOpen,
  handleCloseModal,
  selectedRow,
  handleSaveChanges,
  getALLTeacher,
  dataTeacher,
  getALLStudent,
  dataStudent,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getALLTeacher();
    getALLStudent();
  }, [getALLTeacher, getALLStudent]);

  useEffect(() => {
    if (selectedRow) {
      setValue("student_id", selectedRow.student_id);
      setValue("teacher_id", selectedRow.teacher_id);
      setValue("date", formatDate(selectedRow.date));
      setValue("activity", selectedRow.activity);
      setValue("note", selectedRow.note);
    } else {
      setValue("student_id", "");
      setValue("teacher_id", "");
      setValue("date", "");
      setValue("activity", "");
      setValue("note", "");
    }
  }, [selectedRow, setValue]);

  const onSubmit = (data) => {
    let id = selectedRow?.id || 0;
    handleSaveChanges(id, data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Data</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormControl mb={4}>
                <FormLabel htmlFor="birth_date">Date</FormLabel>
                <Input
                  type="date"
                  id="date"
                  {...register("date")}
                />
                {errors.date && (
                  <Text fontSize="xs" color="tomato">
                    {errors.date.message}
                  </Text>
                )}
              </FormControl>
              <FormLabel htmlFor="student_id">Student Name</FormLabel>
              <Select
                name="student_id"
                id="student_id"
                {...register("student_id")}
              >
                <option value="">Select Student</option>
                {dataStudent.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.nis} || {student.name}
                  </option>
                ))}
              </Select>
              {errors.teacher_id && (
                <Text fontSize="xs" color="tomato">
                  {errors.teacher_id.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="teacher_id">Teacher Name</FormLabel>
              <Select
                name="teacher_id"
                id="teacher_id"
                {...register("teacher_id")}
              >
                <option value="">Select Teacher</option>
                {dataTeacher.map((teacher) => (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.name}
                  </option>
                ))}
              </Select>
              {errors.teacher_id && (
                <Text fontSize="xs" color="tomato">
                  {errors.teacher_id.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="activity">Activity</FormLabel>
              <Textarea id="activity" {...register("activity")} />
              {errors.activity && (
                <Text fontSize="xs" color="tomato">
                  {errors.activity.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="note">Note</FormLabel>
              <Textarea id="note" {...register("note")} />
              {errors.note && (
                <Text fontSize="xs" color="tomato">
                  {errors.note.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const reduxState = (state) => ({
  dataTeacher: state.getData.dataTeacher.data,
  dataStudent: state.getData.dataStudent.data,
  loadingData: state.getData.dataTeacher.loading,
  LoadingData2: state.getData.dataStudent.loading,
});

const reduxDispatch = (dispatch) => {
  return {
    getALLTeacher: () => dispatch(getALLTeacher()),
    getALLStudent: () => dispatch(getALLStudent()),
  };
};

export default connect(reduxState, reduxDispatch)(ClassModal);
