const intitiasiStateGlobal = {
  dataTeacher: {
    data: [],
    loading: true,
  },
  dataClass: {
    data: [],
    loading: true,
  },
  dataStudent: {
    data: [],
    loading: true,
  },
  dataPMT: {
    data: [],
    loading: true,
  },
  dataPayment: {
    data: [],
    loading: true,
  },
  dataWeekly: {
    data: [],
    loading: true,
  },
  dataDaily: {
    data: [],
    loading: true,
  },
  dataDashboard: {
    data: [],
    loading: true,
  },
  dataDashboardTeacher: {
    data: [],
    loading: true,
  },
  dataAuth: {
    auth: false,
    loading: true,
  },
  dataDailyStudent: {
    data: [],
    loading: true,
  },
  dataWeeklyStudent: {
    data: [],
    loading: true,
  },
  dataPaymentStudent: {
    data: [],
    loading: true,
  },
  dataPMTYear: {
    data: [],
    loading: true,
  },
  dataDashboardStudent: {
    data: [],
    loading: true,
  },
};

export const getData = (state = intitiasiStateGlobal, action) => {
  switch (action.type) {
    case "GET_DATA_TEACHER":
    case "UPDATE_DATA_TEACHER":
    case "DELETE_DATA_TEACHER":
    case "CREATE_DATA_TEACHER":
      return {
        ...state,
        dataTeacher: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_CLASS":
    case "UPDATE_DATA_CLASS":
    case "DELETE_DATA_CLASS":
    case "CREATE_DATA_CLASS":
      return {
        ...state,
        dataClass: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_STUDENT":
    case "UPDATE_DATA_STUDENT":
    case "DELETE_DATA_STUDENT":
    case "CREATE_DATA_STUDENT":
      return {
        ...state,
        dataStudent: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_PMT":
    case "UPDATE_DATA_PMT":
    case "DELETE_DATA_PMT":
    case "CREATE_DATA_PMT":
      return {
        ...state,
        dataPMT: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_PAYMENT":
    case "UPDATE_DATA_PAYMENT":
    case "DELETE_DATA_PAYMENT":
    case "CREATE_DATA_PAYMENT":
      return {
        ...state,
        dataPayment: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_WEEKLY":
    case "UPDATE_DATA_WEEKLY":
    case "DELETE_DATA_WEEKLY":
    case "CREATE_DATA_WEEKLY":
      return {
        ...state,
        dataWeekly: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_DAILY":
    case "UPDATE_DATA_DAILY":
    case "DELETE_DATA_DAILY":
    case "CREATE_DATA_DAILY":
      return {
        ...state,
        dataDaily: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_DASHBOARD":
      return {
        ...state,
        dataDashboard: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_DASHBOARD_TEACHER":
      return {
        ...state,
        dataDashboardTeacher: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_LOGIN":
      return {
        ...state,
        dataAuth: {
          auth: action.value,
          loading: false,
        },
      };
    case "GET_DATA_DAILY_STUDENT":
      return {
        ...state,
        dataDailyStudent: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_WEEKLY_STUDENT":
      return {
        ...state,
        dataWeeklyStudent: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_PAYMENT_STUDENT":
      return {
        ...state,
        dataPaymentStudent: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_PMT_YEAR":
      return {
        ...state,
        dataPMTYear: {
          data: action.value,
          loading: false,
        },
      };
    case "GET_DATA_DASHBOARD_STUDENT":
      return {
        ...state,
        dataDashboardStudent: {
          data: action.value,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default getData;
