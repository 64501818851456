import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { GET_DATA_PAYMENT } from "../../../configs/redux/action";
import Card from "components/card/Card";
import PaymentTable from "./paymentTable";
import FilterComponent from "../../../components/table/FilterComponent";

const Payment = ({ GET_DATA_PAYMENT, dataPayment, loadingData }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await GET_DATA_PAYMENT();
    };
    parseUserInfo();
  }, [GET_DATA_PAYMENT]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data Laporan
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
        </Flex>

        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataPayment.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <PaymentTable
              data={dataPayment}
              resetPaginationToggle={resetPaginationToggle}
              filterText={filterText}
            />
          </>
        )}
      </Card>
    </Box>
  );
};

const reduxState = (state) => ({
  dataPayment: state.getData.dataPayment.data,
  loadingData: state.getData.dataPayment.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    GET_DATA_PAYMENT: () => dispatch(GET_DATA_PAYMENT()),
  };
};

export default connect(reduxState, reduxDispatch)(Payment);
