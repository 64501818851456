import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginComponent from "./Login";
import { useEffect } from "react";
import Cookies from "js-cookie";
import React from "react";

const Login = () => {
  useEffect(() => {
    if (Cookies.get("token")) {
      window.location.href = "/student/dashboard";
    }
  }, []);

  document.title = "Login";
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <LoginComponent />
    </GoogleOAuthProvider>
  );
};
export default Login;
