import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { getALLStudent } from "../../../configs/redux/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    student_id: yup.number().positive().integer().required(),
    item: yup.string().required(),
    amount: yup.number().positive().integer().required(),
  })
  .required();

const ClassModal = ({
  isOpen,
  handleCloseModal,
  selectedRow,
  handleSaveChanges,
  getALLStudent,
  dataStudent,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getALLStudent();
  }, [getALLStudent]);

  useEffect(() => {
    if (selectedRow) {
      setValue("student_id", selectedRow.student_id);
      setValue("item", selectedRow.item);
      setValue("amount", selectedRow.amount);
    } else {
      setValue("student_id", "");
      setValue("item", "");
      setValue("amount", 0);
    }
  }, [selectedRow, setValue]);

  const onSubmit = (data) => {
    let id = selectedRow?.payment_id || 0;
    handleSaveChanges(id, data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Data Pembayaran</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel htmlFor="student_id">Student Name</FormLabel>
              <Select
                name="student_id"
                id="student_id"
                {...register("student_id")}
              >
                <option value="">Select Student</option>
                {dataStudent.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.nis} || {student.name}
                  </option>
                ))}
              </Select>
              {errors.student_id && (
                <Text fontSize="xs" color="tomato">
                  {errors.student_id.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="item">Item</FormLabel>
              <Input type="text" name="item" id="item" {...register("item")} />
              {errors.item && (
                <Text fontSize="xs" color="tomato">
                  {errors.item.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="amount">Nominal</FormLabel>
              <Input
                type="number"
                name="amount"
                id="amount"
                {...register("amount")}
              />
              {errors.amount && (
                <Text fontSize="xs" color="tomato">
                  {errors.amount.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const reduxState = (state) => ({
  dataStudent: state.getData.dataStudent.data,
  LoadingData2: state.getData.dataStudent.loading,
});

const reduxDispatch = (dispatch) => {
  return {
    getALLStudent: () => dispatch(getALLStudent()),
  };
};

export default connect(reduxState, reduxDispatch)(ClassModal);
