import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdLock,
} from "react-icons/md";

import TeacherDashboard from "views/teacher/dashboard";
import ClassList from "views/teacher/class";
import StudentList from "views/teacher/student";
import TeacherList from "views/teacher/teachers";
import TeacherDailyReport from "views/teacher/daily-report";
import TeacherMonthlyReport from "views/teacher/monthly-report";
import TeacherPayment from "views/teacher/payment";
import TeacherFoodReport from "views/teacher/food-report";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignInTeacher from "views/auth/teacher";
import SignInAdmin from "views/auth/admin";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TeacherDashboard,
  },
  {
    name: "Data Kelas",
    layout: "/admin",
    path: "/class",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: ClassList,
  },
  {
    name: "Data Murid",
    layout: "/admin",
    path: "/student",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: StudentList,
  },

  {
    name: "Laporan Harian",
    layout: "/admin",
    path: "/daily-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherDailyReport,
  },
  {
    name: "Laporan Mingguan",
    layout: "/admin",
    path: "/monthly-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherMonthlyReport,
  },
  {
    name: "Pembayaran",
    layout: "/admin",
    path: "/payment",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherPayment,
  },
  {
    name: "PMT",
    layout: "/admin",
    path: "/food-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherFoodReport,
  },

  {
    name: "Data Pengajar",
    layout: "/admin",
    path: "/teachers",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherList,
  },
  {
    name: "Login Murid",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },

  {
    name: "Login Pengajar",
    layout: "/auth",
    path: "/teacher",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInTeacher,
  },
  {
    name: "Login Admin",
    layout: "/auth",
    path: "/admin",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInAdmin,
  },
];

export default routes;
