// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarTeacher.js";
import Sidebar from "components/sidebarTeacher";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import TeacherRoutes from "TeacherRoutes.js";

import { connect } from "react-redux";
import { tesLoginTeacher } from "configs/redux/action/";
import TeacherRouter from "components/auth/TeacherRoute";

// Custom Chakra theme
function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  const getActiveRoute = (TeacherRoutes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < TeacherRoutes.length; i++) {
      if (TeacherRoutes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(TeacherRoutes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (TeacherRoutes[i].category) {
        let categoryActiveRoute = getActiveRoute(TeacherRoutes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            TeacherRoutes[i].layout + TeacherRoutes[i].path
          ) !== -1
        ) {
          return TeacherRoutes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (TeacherRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < TeacherRoutes.length; i++) {
      if (TeacherRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(TeacherRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (TeacherRoutes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(TeacherRoutes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(
            TeacherRoutes[i].layout + TeacherRoutes[i].path
          ) !== -1
        ) {
          return TeacherRoutes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (TeacherRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < TeacherRoutes.length; i++) {
      if (TeacherRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(TeacherRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (TeacherRoutes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(TeacherRoutes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(
            TeacherRoutes[i].layout + TeacherRoutes[i].path
          ) !== -1
        ) {
          return TeacherRoutes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (TeacherRoutes) => {
    return TeacherRoutes.map((prop, key) => {
      if (prop.layout === "/teacher") {
        return (
          <TeacherRouter
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
            strict={prop.strict}
            redirectTo={"/auth/teacher"}
            Auth={props.tesLoginTeacher}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={TeacherRoutes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"LMS TK BUDI UTOMO"}
                brandText={getActiveRoute(TeacherRoutes)}
                secondary={getActiveNavbar(TeacherRoutes)}
                message={getActiveNavbarText(TeacherRoutes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            p={{ base: "20px", md: "20px" }}
            pe="10px"
            minH="100vh"
            pt={{ base: "0px", md: "50px" }}
          >
            <Switch>
              {getRoutes(TeacherRoutes)}
              <Redirect from="/" to="/teacher/dashboard" />
            </Switch>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
      <Box display={{ base: "block", md: "none" }}> </Box>
    </Box>
  );
}

const reduxDispatch = () => (dispatch) => {
  return {
    tesLoginTeacher: () => dispatch(tesLoginTeacher()),
  };
};

export default connect(null, reduxDispatch)(Dashboard);
