import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getALLClass,
  createClass,
  deleteClass,
  updateClass,
} from "../../../configs/redux/action";
import Swal from "sweetalert2";
import Card from "components/card/Card";
import ClassTable from "./ClassTable";
import ClassModal from "./ClassModal";
import FilterComponent from "../../../components/table/FilterComponent";

const Class = ({
  getALLClass,
  dataClass,
  loadingData,
  createClass,
  deleteClass,
  updateClass,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Refresh, setRefresh] = useState(0);

  const handleSaveChanges = async (id, update) => {
    if (id === 0) {
      const res = await createClass(update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await updateClass(id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteClass(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(Refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleUpdate = (id) => {
    const selectedRow = dataClass.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setIsModalOpen(true);
  };
  const handleAddNew = () => {
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getALLClass();
      console.log(res);
    };
    parseUserInfo();
  }, [getALLClass, Refresh]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data Kelas
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Button colorScheme="blue" size="sm" onClick={() => handleAddNew()}>
            Tambah Kelas
          </Button>
        </Flex>
        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataClass.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <ClassTable
              data={dataClass}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              resetPaginationToggle={resetPaginationToggle}
              filterText={filterText}
            />
          </>
        )}
      </Card>

      <ClassModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChanges}
      />
    </Box>
  );
};

const reduxState = (state) => ({
  dataClass: state.getData.dataClass.data,
  loadingData: state.getData.dataClass.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getALLClass: () => dispatch(getALLClass()),
    createClass: (data) => dispatch(createClass(data)),
    deleteClass: (id) => dispatch(deleteClass(id)),
    updateClass: (id, data) => dispatch(updateClass(id, data)),
  };
};

export default connect(reduxState, reduxDispatch)(Class);
