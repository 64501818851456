import { Box, Center, Spinner } from "@chakra-ui/react";
import ComplexTable from "./components/ComplexTable";
import React, { useEffect } from "react";
import { getPaymentStudent } from "../../../configs/redux/action";
import { connect } from "react-redux";

const Payment = ({ getPaymentStudent, dataPaymentStudent, loadingData }) => {
  useEffect(() => {
    getPaymentStudent();
  }, [getPaymentStudent]);

  return (
    <Box mt={{ base: "130px", md: "100px", xl: "80px" }}>
      {loadingData ? (
        <Center mt="70px">
          <Spinner size="xl" />
        </Center>
      ) : (
        <ComplexTable data={dataPaymentStudent} />
      )}
    </Box>
  );
};
const reduxState = (state) => ({
  dataPaymentStudent: state.getData.dataPaymentStudent.data,
  loadingData: state.getData.dataPaymentStudent.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getPaymentStudent: () => dispatch(getPaymentStudent()),
  };
};

export default connect(reduxState, reduxDispatch)(Payment);
