import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import StudentLayout from "layouts/student";
import TeacherLayout from "layouts/teacher";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { Provider } from "react-redux";
import { store } from "configs/redux/store";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/student`} component={StudentLayout} />
            <Route path={`/teacher`} component={TeacherLayout} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
