import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdPerson, MdHome } from "react-icons/md";

// Student Imports
import StudentDashboard from "views/student/dashboard";
import StudentDailyReport from "views/student/daily-report";
import StudentMonthlyReport from "views/student/monthly-report";
import StudentPayment from "views/student/payment";
import StudentFoodReport from "views/student/food-report";

const StudentRoutes = [
  // Student Routes
  {
    name: "Dashboard",
    layout: "/student",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: StudentDashboard,
  },
 
  {
    name: "Laporan Harian",
    layout: "/student",
    path: "/daily-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: StudentDailyReport,
  },
  {
    name: "Laporan Mingguan",
    layout: "/student",
    path: "/monthly-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: StudentMonthlyReport,
  },
  {
    name: "Pembayaran",
    layout: "/student",
    path: "/payment",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: StudentPayment,
  },
  {
    name: "Makanan Tambahan",
    layout: "/student",
    path: "/food-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: StudentFoodReport,
  },
];

export default StudentRoutes;
