import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getALLTeacher,
  createTeacher,
  deleteTeacher,
  updateTeacher,
} from "../../../configs/redux/action";
import Swal from "sweetalert2";
import Card from "components/card/Card";
import TeacherTable from "./TeacherTable";
import TeacherModal from "./TeacherModal";
import FilterComponent from "../../../components/table/FilterComponent";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Teacher = ({
  getALLTeacher,
  dataTeacher,
  loadingData,
  createTeacher,
  deleteTeacher,
  updateTeacher,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [passwordModalOpen, setPasswordModalOpen] = useState(true);
  const [passwordInput, setPasswordInput] = useState("");
  let history = useHistory();

  const handleSaveChanges = async (id, update) => {
    if (id === 0) {
      const res = await createTeacher(update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await updateTeacher(id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(refresh + 1);
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteTeacher(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleUpdate = (id) => {
    const selectedRow = dataTeacher.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setIsModalOpen(true);
  };

  const handleAddNew = () => {
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  const handlePasswordInputChange = (e) => {
    setPasswordInput(e.target.value);
  };

  const handlePasswordSubmit = () => {
    const currentDate = moment().format("DDMMYYYY");
    const password = `tk-${currentDate}`;
    if (passwordInput === password) {
      setPasswordModalOpen(false);
    } else {
      history.push("/teacher/dashboard");
    }
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getALLTeacher();
      console.log(res);
    };
    parseUserInfo();
  }, [getALLTeacher, refresh]);

  if (passwordModalOpen) {
    return (
      <Modal isOpen={passwordModalOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gak Boleh</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel></FormLabel>
              <Input
                type="password"
                value={passwordInput}
                onChange={handlePasswordInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlePasswordSubmit}>
              xxx
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data Kelas
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Button colorScheme="blue" size="sm" onClick={handleAddNew}>
            Tambah Kelas
          </Button>
        </Flex>
        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataTeacher.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <TeacherTable
              data={dataTeacher}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              resetPaginationToggle={resetPaginationToggle}
              filterText={filterText}
            />
          </>
        )}
      </Card>

      <TeacherModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChanges}
      />
    </Box>
  );
};

const reduxState = (state) => ({
  dataTeacher: state.getData.dataTeacher.data,
  loadingData: state.getData.dataTeacher.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getALLTeacher: () => dispatch(getALLTeacher()),
    createTeacher: (data) => dispatch(createTeacher(data)),
    deleteTeacher: (id) => dispatch(deleteTeacher(id)),
    updateTeacher: (id, data) => dispatch(updateTeacher(id, data)),
  };
};

export default connect(reduxState, reduxDispatch)(Teacher);
