import React from "react";

// Chakra imports
import { Flex, Heading } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <Heading as="h1" size="lg" color="teal" my="30px">
        LMS TK BUDUT
      </Heading>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
