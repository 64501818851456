import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getDataWeeklyReport,
  createWeeklyReport,
  deleteWeeklyReport,
  updateWeeklyReport,
  createWeeklyReportBatch,
} from "../../../configs/redux/action";
import Swal from "sweetalert2";
import Card from "components/card/Card";
import WeeklyReportTable from "./monthlyTable";
import WeeklyReportModal from "./monthlyModal";
import WeeklyBatchModal from "./monthlyBatchModal";
import FilterComponent from "../../../components/table/FilterComponent";

const WeeklyReport = ({
  getDataWeeklyReport,
  dataWeekly,
  loadingData,
  createWeeklyReport,
  deleteWeeklyReport,
  updateWeeklyReport,
  createWeeklyReportBatch,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);
  const [Refresh, setRefresh] = useState(0);

  const handleSaveChanges = async (id, update) => {
    if (id === 0) {
      const res = await createWeeklyReport(update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await updateWeeklyReport(id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleSaveBatch = async (data) => {
    const res = await createWeeklyReportBatch(data);
    if (res.status === 200) {
      Swal.fire("Success!", "Your data has been created.", "success");
    } else {
      Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsBatchModalOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
    setIsBatchModalOpen(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteWeeklyReport(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(Refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleUpdate = (id) => {
    const selectedRow = dataWeekly.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setIsModalOpen(true);
  };

  const handleAddNew = () => {
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleAddBatch = () => {
    setSelectedRow(null);
    setIsBatchModalOpen(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getDataWeeklyReport();
      console.log(Refresh);
    };
    parseUserInfo();
  }, [getDataWeeklyReport, Refresh]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data Laporan
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Button colorScheme="blue" size="sm" onClick={() => handleAddNew()}>
            Tambah Laporan Mingguan
          </Button>
          <Button colorScheme="blue" size="sm" onClick={() => handleAddBatch()}>
            Tambah Laporan Mingguan (Batch)
          </Button>
        </Flex>
        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataWeekly.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <WeeklyReportTable
              data={dataWeekly}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              resetPaginationToggle={resetPaginationToggle}
              filterText={filterText}
            />
          </>
        )}
      </Card>

      <WeeklyReportModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChanges}
      />
      <WeeklyBatchModal
        isOpen={isBatchModalOpen}
        handleCloseModal={handleCloseModal}
        handleSaveChanges={handleSaveBatch}
      />
    </Box>
  );
};

const reduxState = (state) => ({
  dataWeekly: state.getData.dataWeekly.data,
  loadingData: state.getData.dataWeekly.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getDataWeeklyReport: () => dispatch(getDataWeeklyReport()),
    createWeeklyReport: (data) => dispatch(createWeeklyReport(data)),
    deleteWeeklyReport: (id) => dispatch(deleteWeeklyReport(id)),
    updateWeeklyReport: (id, data) => dispatch(updateWeeklyReport(id, data)),
    createWeeklyReportBatch: (data) => dispatch(createWeeklyReportBatch(data)),
  };
};

export default connect(reduxState, reduxDispatch)(WeeklyReport);
