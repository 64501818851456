import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { getALLTeacher } from "../../../configs/redux/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    note: yup.string().required(),
    year: yup.number().positive().integer().required(),
    month: yup.number().positive().integer().required(),
  })
  .required();

const ClassModal = ({
  isOpen,
  handleCloseModal,
  selectedRow,
  handleSaveChanges,
  getALLTeacher,
  dataTeacher,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getALLTeacher();
  }, [getALLTeacher]);

  useEffect(() => {
    if (selectedRow) {
      setValue("year", selectedRow.year);
      setValue("month", selectedRow.month);
      setValue("note", selectedRow.note);
    } else {
      setValue("year", "");
      setValue("month", "");
      setValue("note", "");
    }
  }, [selectedRow, setValue]);

  const onSubmit = (data) => {
    let id = selectedRow?.id || 0;
    handleSaveChanges(id, data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Data</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel htmlFor="year">Tahun</FormLabel>
              <Input type="number" id="year" {...register("year")} />
              {errors.year && (
                <Text fontSize="xs" color="tomato">
                  {errors.year.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="month">Bulan</FormLabel>
              <Input type="number" id="month" {...register("month")} />
              {errors.month && (
                <Text fontSize="xs" color="tomato">
                  {errors.month.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="note">Catatan</FormLabel>
              <Textarea id="note" {...register("note")} />
              {errors.note && (
                <Text fontSize="xs" color="tomato">
                  {errors.note.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const reduxState = (state) => ({
  dataTeacher: state.getData.dataTeacher.data,
  loadingData: state.getData.dataTeacher.loading,
});

const reduxDispatch = (dispatch) => {
  return {
    getALLTeacher: () => dispatch(getALLTeacher()),
  };
};

export default connect(reduxState, reduxDispatch)(ClassModal);
