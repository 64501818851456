import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: yup.string().required(),
  })
  .required();

const TeacherModal = ({
  isOpen,
  handleCloseModal,
  selectedRow,
  handleSaveChanges,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (selectedRow) {
      setValue("name", selectedRow.name);
      setValue("email", selectedRow.email);
      setValue("phone_number", selectedRow.phone_number);
    } else {
      setValue("name", "");
      setValue("email", "");
      setValue("phone_number", "");
    }
  }, [selectedRow, setValue]);

  const onSubmit = (data) => {
    let id = selectedRow?.id || 0;
    handleSaveChanges(id, data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Data</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input type="text" id="name" {...register("name")} />
              {errors.name && (
                <Text fontSize="xs" color="tomato">
                  {errors.name.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input type="email" id="email" {...register("email")} />
              {errors.email && (
                <Text fontSize="xs" color="tomato">
                  {errors.email.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="phone_number">Phone Number</FormLabel>
              <Input type="text" id="phone_number" {...register("phone_number")} />
              {errors.phone_number && (
                <Text fontSize="xs" color="tomato">
                  {errors.phone_number.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default TeacherModal;
