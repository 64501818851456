import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getAllDataDaily,
  createDailyReport,
  createBatchDailyReport,
  deleteDailyReport,
  updateDailyReport,
} from "../../../configs/redux/action";
import Swal from "sweetalert2";
import Card from "components/card/Card";
import DailyReportTable from "./DailyTable";
import DailyReportModal from "./DailyModal";
import DailyBatchModal from "./DailyBatchModal";
import FilterComponent from "../../../components/table/FilterComponent";

const DailyReport = ({
  getAllDataDaily,
  dataDaily,
  totalRows,
  loadingData,
  createDailyReport,
  createBatchDailyReport,
  deleteDailyReport,
  updateDailyReport,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);
  const [Refresh, setRefresh] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleSaveChanges = async (id, update) => {
    if (id === 0) {
      const res = await createDailyReport(update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await updateDailyReport(id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalOpen(false);
    setIsBatchModalOpen(false);
  };

  const handleSaveBatch = async (data) => {
    const res = await createBatchDailyReport(data);

    if (res.status === 200) {
      Swal.fire("Success!", "Your data has been created.", "success");
    } else {
      Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
    }

    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsBatchModalOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
    setIsBatchModalOpen(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteDailyReport(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(Refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleUpdate = (id) => {
    const selectedRow = dataDaily.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setIsModalOpen(true);
  };
  const handleAddNew = () => {
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleBatchAddNew = () => {
    setSelectedRow(null);
    setIsBatchModalOpen(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
      setPage(1);
      setLimit(10);
    }
  };

  const handleSearch = async (e) => {
    await setFilterText(e.target.value);
    setRefresh(Refresh + 1);
  };

  const handlePageChange = async (page) => {
    await setPage(page);
    setRefresh(Refresh + 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    await setPage(page);
    await setLimit(newPerPage);
    setRefresh(Refresh + 1);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getAllDataDaily(page, limit, filterText);
      console.log(Refresh);
    };
    parseUserInfo();
  }, [getAllDataDaily, Refresh]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data Laporan
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Button colorScheme="blue" size="sm" onClick={() => handleAddNew()}>
            Tambah Laporan Harian
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => handleBatchAddNew()}
          >
            Tambah Laporan Harian (Batch)
          </Button>
        </Flex>
        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataDaily.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <DailyReportTable
              loadingData={loadingData}
              totalRows={totalRows}
              data={dataDaily}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              resetPaginationToggle={resetPaginationToggle}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </>
        )}
      </Card>

      <DailyReportModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChanges}
      />

      <DailyBatchModal
        isOpen={isBatchModalOpen}
        handleCloseModal={handleCloseModal}
        handleSaveChanges={handleSaveBatch}
      />
    </Box>
  );
};

const reduxState = (state) => ({
  dataDaily: state.getData.dataDaily.data,
  loadingData: state.getData.dataDaily.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getAllDataDaily: (page, limit, filterText) =>
      dispatch(getAllDataDaily(page, limit, filterText)),
    createDailyReport: (data) => dispatch(createDailyReport(data)),
    deleteDailyReport: (id) => dispatch(deleteDailyReport(id)),
    updateDailyReport: (id, data) => dispatch(updateDailyReport(id, data)),
    createBatchDailyReport: (data) => dispatch(createBatchDailyReport(data)),
  };
};

export default connect(reduxState, reduxDispatch)(DailyReport);
