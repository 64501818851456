import React from "react";
import DataTable from "react-data-table-component";
import { Button, Flex } from "@chakra-ui/react";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import moment from "moment";
import "moment/locale/id";

const DailyTable = ({
  data,
  handleDelete,
  handleUpdate,
  handleModalHistory,
  resetPaginationToggle,
  filterText,
  handleDeleteHistory,
}) => {
  const columns = [
    {
      name: "Siswa",
      selector: (row) => row.nis + " | " + row.name,
      sortable: true,
    },
    {
      name: "Item",
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status_payment,
      sortable: true,
    },
    {
      name: "Total Pembayaran",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Pembayaran Masuk",
      selector: (row) => row.paid,
      sortable: true,
    },
    {
      name: "Sisa Pembayaran",
      selector: (row) => row.notpaid,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            colorScheme="blue"
            onClick={() => handleUpdate(row.payment_id)}
          >
            <RiEditLine />
          </Button>
          <Button
            colorScheme="red"
            mr={2}
            onClick={() => handleDelete(row.payment_id)}
          >
            <RiDeleteBinLine />
          </Button>
        </div>
      ),
    },
  ];

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  const subTableColumns = [
    {
      name: "History Pembayaran",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Nominal Pembayaran",
      selector: (row) => row.amount,
      // sortable: true,
    },
    {
      name: "Tanggal Pembayaran",
      selector: (row) => row.date_paid,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      // sortable: true,
    },
    // Add other columns as needed
  ];

  const SubTableComponent = ({ data }) => {
    const expandedRows = data.history_payments_json.map((row) => ({
      payment_name: data.item + " | " + data.name,
      payment_id: data.payment_id,
      history_id: row.id,
      name: row.name,
      amount: row.amount,
      date_paid: row.date_paid
        ? moment(row.date_paid).format("DD-MMMM-YYYY")
        : "-",
      action: (
        <>
          <Button
            colorScheme="yellow"
            size="sm"
            mr="2"
            onClick={() =>
              handleModalHistory(data.item + " | " + data.name, row)
            }
          >
            <RiEditLine />
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => handleDeleteHistory(row.id)}
          >
            <RiDeleteBinLine />
          </Button>
        </>
      ),
    }));
    return (
      <>
        <Flex px="25px" justify="space-between" my="10px" align="center">
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() =>
              handleModalHistory(data.item + " | " + data.name, [
                data.payment_id,
              ])
            }
          >
            +
          </Button>
        </Flex>
        <DataTable
          columns={subTableColumns}
          data={expandedRows}
          noHeader
          pagination={false}
        />
      </>
    );
  };

  return (
    <div className="p-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortFieldId={1}
        expandableRows
        expandableRowsComponent={SubTableComponent}
      />
    </div>
  );
};

export default DailyTable;
