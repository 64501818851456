import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
const TeacherRoute = ({ component: Component, Auth, ...rest }) => {
  const token = Cookies.get("token");
  const history = useHistory();
  useEffect(() => {
    const cektAuth = async () => {
      const cekLogin = await Auth();
      if (!cekLogin) {
        Cookies.remove("token");
        history.push(rest.redirectTo);
      }
    };
    cektAuth();
  }, [Auth, history, rest.redirectTo]);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: rest.redirectTo,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default TeacherRoute;
