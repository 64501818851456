import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  GET_DATA_PAYMENT,
  createPayment,
  deletePayment,
  updatePayment,
  createPaymentNewStudent,
  createPaymentHistory,
  updatePaymentHistory,
  deletePaymentHistory,
} from "../../../configs/redux/action";
import Swal from "sweetalert2";
import Card from "components/card/Card";
import PaymentTable from "./paymentTable";
import PaymentModal from "./paymentModal";
import PaymentNewStudent from "./paymentNewStudentModal";
import PaymentHistoryModal from "./paymentHistoryModal";
import FilterComponent from "../../../components/table/FilterComponent";

const Payment = ({
  GET_DATA_PAYMENT,
  dataPayment,
  loadingData,
  createPayment,
  createPaymentNewStudent,
  deletePayment,
  updatePayment,
  createPaymentHistory,
  updatePaymentHistory,
  deletePaymentHistory,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNewStudentOpen, setIsModalNewStudentOpen] = useState(false);
  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);
  // const [selectedSubRow, setSelectedSubRow] = useState(null);

  const [Refresh, setRefresh] = useState(0);

  const handleSaveChanges = async (id, update) => {
    if (id === 0) {
      const res = await createPayment(update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await updatePayment(id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleSaveChangesHistory = async (id, update) => {
    if (id === 0) {
      const res = await updatePaymentHistory(update.history_id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await createPaymentHistory(id, update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalHistoryOpen(false);
  };

  const handleSaveNewPaymentStudent = async (data) => {
    const res = await createPaymentNewStudent(data);
    if (res.status === 200) {
      Swal.fire("Success!", "Success Add New Student Payments.", "success");
    } else {
      Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalNewStudentOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
    setIsModalHistoryOpen(false);
    setIsModalNewStudentOpen(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deletePayment(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(Refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleDeleteHistory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deletePaymentHistory(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(Refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleUpdate = (id) => {
    const selectedRow = dataPayment.find((row) => row.payment_id === id);
    setSelectedRow(selectedRow);
    setIsModalOpen(true);
  };
  const handleAddNew = () => {
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleAddNewStudentPayment = () => {
    setSelectedRow(null);
    setIsModalNewStudentOpen(true);
  };
  // history

  const handleModalHistory = (data, subData) => {
    let dataModal = { ...subData, data };
    setSelectedRow(dataModal);
    setIsModalHistoryOpen(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await GET_DATA_PAYMENT();
    };
    parseUserInfo();
  }, [GET_DATA_PAYMENT, Refresh]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data Laporan
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
            />
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Button colorScheme="blue" size="sm" onClick={() => handleAddNew()}>
            Tambah Pembayaran
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => handleAddNewStudentPayment()}
          >
            Tambah Pembayaran Murid Baru
          </Button>
        </Flex>

        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataPayment.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <PaymentTable
              data={dataPayment}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              handleDeleteHistory={handleDeleteHistory}
              handleModalHistory={handleModalHistory}
              resetPaginationToggle={resetPaginationToggle}
              filterText={filterText}
            />
          </>
        )}
      </Card>

      <PaymentModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChanges}
      />
      <PaymentNewStudent
        isOpen={isModalNewStudentOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveNewPaymentStudent}
      />
      <PaymentHistoryModal
        isOpen={isModalHistoryOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChangesHistory}
      />
    </Box>
  );
};

const reduxState = (state) => ({
  dataPayment: state.getData.dataPayment.data,
  loadingData: state.getData.dataPayment.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    GET_DATA_PAYMENT: () => dispatch(GET_DATA_PAYMENT()),
    createPayment: (data) => dispatch(createPayment(data)),
    deletePayment: (id) => dispatch(deletePayment(id)),
    updatePayment: (id, data) => dispatch(updatePayment(id, data)),
    createPaymentNewStudent: (data) => dispatch(createPaymentNewStudent(data)),
    createPaymentHistory: (id, data) =>
      dispatch(createPaymentHistory(id, data)),
    deletePaymentHistory: (id) => dispatch(deletePaymentHistory(id)),
    updatePaymentHistory: (id, data) =>
      dispatch(updatePaymentHistory(id, data)),
  };
};

export default connect(reduxState, reduxDispatch)(Payment);
