// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarStudent.js";
import Sidebar from "components/sidebarStudent";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import StudentRoutes from "StudentRoutes.js";

import { connect } from "react-redux";
import { tesLogin } from "configs/redux/action/";
import PrivateRoute from "components/auth/PrivateRoute";
import MobileNavigation from "components/MobileButton";

// Custom Chakra theme
function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  const getActiveRoute = (StudentRoutes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < StudentRoutes.length; i++) {
      if (StudentRoutes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(StudentRoutes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (StudentRoutes[i].category) {
        let categoryActiveRoute = getActiveRoute(StudentRoutes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            StudentRoutes[i].layout + StudentRoutes[i].path
          ) !== -1
        ) {
          return StudentRoutes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (StudentRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < StudentRoutes.length; i++) {
      if (StudentRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(StudentRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (StudentRoutes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(StudentRoutes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(
            StudentRoutes[i].layout + StudentRoutes[i].path
          ) !== -1
        ) {
          return StudentRoutes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (StudentRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < StudentRoutes.length; i++) {
      if (StudentRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(StudentRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (StudentRoutes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(StudentRoutes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(
            StudentRoutes[i].layout + StudentRoutes[i].path
          ) !== -1
        ) {
          return StudentRoutes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (StudentRoutes) => {
    return StudentRoutes.map((prop, key) => {
      if (prop.layout === "/student") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
            strict={prop.strict}
            redirectTo={"/auth/login"}
            Auth={props.tesLogin}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={StudentRoutes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"LMS TK BUDI UTOMO"}
                brandText={getActiveRoute(StudentRoutes)}
                secondary={getActiveNavbar(StudentRoutes)}
                message={getActiveNavbarText(StudentRoutes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            p={{ base: "20px", md: "20px" }}
            pe="10px"
            minH="100vh"
            pt={{ base: "0px", md: "50px" }}
          >
            <Switch>
              {getRoutes(StudentRoutes)}
              <Redirect from="/" to="/student/dashboard" />
            </Switch>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
      <Box display={{ base: "block", md: "none" }}>
        {" "}
        <MobileNavigation />
      </Box>
    </Box>
  );
}

const reduxDispatch = () => (dispatch) => {
  return {
    tesLogin: () => dispatch(tesLogin()),
  };
};

export default connect(null, reduxDispatch)(Dashboard);
