import React from "react";
import DataTable from "react-data-table-component";
import { Button, Box, Text, Divider } from "@chakra-ui/react";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import moment from "moment";
import "moment/locale/id";

const ClassTable = ({
  data,
  handleDelete,
  handleUpdate,
  resetPaginationToggle,
  filterText,
}) => {
  const columns = [
    {
      name: "NIS",
      selector: (row) => row.nis,
      sortable: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row.name,
      sortable: true,
    },
    { name: "Kelas", selector: (row) => row.class_name, sortable: true },
    {
      name: "Nama Orang Tua",
      selector: (row) => row.parent_name,
      sortable: true,
    },
    {
      name: "Tanggal Lahir",
      selector: "birth_date",
      cell: (row) => moment(row.birth_date).format("YYYY-MMMM-DD"),
      sortable: true,
    },
    {
      name: "Tempat Lahir",
      selector: (row) => row.birth_place,
      sortable: true,
    },
    {
      name: "Jenis Kelamin",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Alamat",
      selector: (row) => row.address,
    },
    {
      name: "No. Telepon",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Tahun masuk",
      selector: (row) => row.year_of_entry,
      sortable: true,
    },
    {
      name: "Tahun lulus",
      selector: (row) => row.year_of_graduation,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button colorScheme="red" mr={2} onClick={() => handleDelete(row.id)}>
            <RiDeleteBinLine />
          </Button>
          <Button colorScheme="blue" onClick={() => handleUpdate(row.id)}>
            <RiEditLine />
          </Button>
        </div>
      ),
    },
  ];

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  const ExpandableRowComponent = ({ data }) => {
    return (
      <Box p={4}>
        <Text fontSize="xs">Alamat : {data.address}</Text>
        <Text fontSize="xs">Catatan :{data.note}</Text>
      </Box>
    );
  };

  return (
    <div className="p-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortFieldId={1}
        expandableRows
        expandableRowsComponent={ExpandableRowComponent}
      />
    </div>
  );
};

export default ClassTable;
