import React from "react";
import DataTable from "react-data-table-component";
import { Button, Box, Text } from "@chakra-ui/react";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import moment from "moment";
import "moment/locale/id";

const DailyTable = ({
  data,
  handleDelete,
  handleUpdate,
  resetPaginationToggle,
  handlePageChange,
  handlePerRowsChange,
  loadingData,
  totalRows,
}) => {
  const columns = [
    {
      name: "Tanggal",
      selector: "date",
      cell: (row) => moment(row.date).format("DD-MMMM-YYYY"),
      sortable: true,
    },
    {
      name: "NIS",
      selector: (row) => row.nis,
      sortable: true,
    },

    {
      name: "Nama Siswa",
      selector: (row) => row.student_name,
      sortable: true,
    },

    {
      name: "Nama Guru",
      selector: (row) => row.teacher_name,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button colorScheme="red" mr={2} onClick={() => handleDelete(row.id)}>
            <RiDeleteBinLine />
          </Button>
          <Button colorScheme="blue" onClick={() => handleUpdate(row.id)}>
            <RiEditLine />
          </Button>
        </div>
      ),
    },
  ];

  const ExpandableRowComponent = ({ data }) => {
    return (
      <Box p={4}>
        <Text fontSize="xs">activity : {data.activity}</Text>
        <Text fontSize="xs">Catatan :{data.note}</Text>
      </Box>
    );
  };

  return (
    <div className="p-2">
      <DataTable
        columns={columns}
        data={data}
        progressPending={loadingData}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        selectableRows
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortFieldId={1}
        expandableRows
        expandableRowsComponent={ExpandableRowComponent}
      />
    </div>
  );
};

export default DailyTable;
