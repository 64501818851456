import { Box, Stack, Button, Center, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getDashboardStudent } from "../../../configs/redux/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const DashboardStudent = ({ getDashboardStudent, dataDashboardStudent }) => {
  let history = useHistory();
  const buttonStyle = {
    colorScheme: "teal",
    height: "48px",
    width: [
      "75%", // 0-30em
      "75%", // 30em-48em
      "50%", // 48em-62em
      "50%", // 62em+
    ],
    border: "2px",
    size: "lg",
  };

  const routeChange = (newPath) => {
    let path = newPath;
    history.push(path);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getDashboardStudent();
    };
    parseUserInfo();
  }, [getDashboardStudent]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} align="center">
      <Center
        height="48px"
        borderWidth="1px"
        borderRadius="lg"
        borderColor="teal"
        mb="10px"
        width={[
          "100%", // 0-30em
          "75%", // 30em-48em
          "50%", // 48em-62em
          "50%", // 62em+
        ]}
      >
        <Heading fas="h4" size="md" color="teal">
          {dataDashboardStudent?.name || "Loading..."}
        </Heading>
      </Center>
      <Stack spacing={4} direction="column" align="center">
        <Button
          {...buttonStyle}
          onClick={() => routeChange("/student/daily-report")}
        >
          Laporan Harian
        </Button>
        <Button
          {...buttonStyle}
          onClick={() => routeChange("/student/monthly-report")}
        >
          Laporan Mingguan
        </Button>
        <Button
          {...buttonStyle}
          onClick={() => routeChange("/student/food-report")}
        >
          Makanan Tambahan
        </Button>
        <Button
          {...buttonStyle}
          onClick={() => routeChange("/student/payment")}
        >
          Pembayaran
        </Button>
      </Stack>
    </Box>
  );
};

const reduxState = (state) => ({
  dataDashboardStudent: state.getData.dataDashboardStudent.data,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getDashboardStudent: () => dispatch(getDashboardStudent()),
  };
};

export default connect(reduxState, reduxDispatch)(DashboardStudent);
