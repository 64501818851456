import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdPerson, MdHome } from "react-icons/md";

import TeacherDashboard from "views/teacher/dashboard";
import ClassList from "views/teacher/class";
import StudentList from "views/teacher/student";
import TeacherList from "views/teacher/teachers";
import TeacherDailyReport from "views/teacher/daily-report";
import TeacherMonthlyReport from "views/teacher/monthly-report";
import TeacherPayment from "views/teacher/payment";
import TeacherPayment2 from "views/teacher/paymentViewOnly";

import TeacherFoodReport from "views/teacher/food-report";

const TeacherRoutes = [
  // Teacher Routes
  {
    name: "Dashboard",
    layout: "/teacher",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TeacherDashboard,
  },
  {
    name: "Data Kelas",
    layout: "/teacher",
    path: "/class",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: ClassList,
  },
  {
    name: "Data Murid",
    layout: "/teacher",
    path: "/student",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: StudentList,
  },

  {
    name: "Laporan Harian",
    layout: "/teacher",
    path: "/daily-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherDailyReport,
  },
  {
    name: "Laporan Mingguan",
    layout: "/teacher",
    path: "/monthly-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherMonthlyReport,
  },
  {
    name: "Pembayaran",
    layout: "/teacher",
    path: "/payment",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherPayment,
  },
  {
    name: "Pembayaran",
    layout: "/teacher",
    path: "/payment2",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherPayment2,
  },
  {
    name: "PMT",
    layout: "/teacher",
    path: "/food-report",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherFoodReport,
  },

  {
    name: "Data Pengajar",
    layout: "/teacher",
    path: "/teachers",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: TeacherList,
  },
];

export default TeacherRoutes;
