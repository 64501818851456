import React from "react";
import { useHistory } from "react-router-dom";
import { Box, IconButton, Flex } from "@chakra-ui/react";
import { FaSchool } from "react-icons/fa";

function MobileNavigation() {
  let history = useHistory();

  const routeChange = (newPath) => {
    let path = newPath;
    history.push(path);
  };

  return (
    <Flex
      position="fixed"
      bottom="1rem"
      left="0"
      width="100%"
      justifyContent="center"
      bg="teal.500"
    >
      <Box display="inline-block" borderRadius="full" borderWidth="2px">
        <IconButton
          icon={<FaSchool />}
          size="md"
          borderRadius="full"
          colorScheme="white"
          onClick={() => routeChange("/student/dashboard")}
        />
      </Box>
    </Flex>
  );
}

export default MobileNavigation;
