import React, { useRef, useEffect } from "react";
import { Input, Button } from "@chakra-ui/react";

const FilterComponent = ({ filterText, handleSearch, onClear, filterBy }) => {
  const inputRef = useRef(null);
  const filteredBY = filterBy ? filterBy : "nama";
  useEffect(() => {
    inputRef.current.focus(); // Set focus on the input element
  }, []);

  return (
    <>
      <Input
        ref={inputRef}
        id="search"
        type="text"
        placeholder={`Cari berdasarkan ${filteredBY}`}
        aria-label="Search Input"
        value={filterText}
        onChange={handleSearch}
        htmlSize={25}
        width="auto"
        mr="10px"
      />
      <Button colorScheme="yellow" size="sm" type="button" onClick={onClear}>
        X
      </Button>
    </>
  );
};

export default FilterComponent;
