import React from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  Image,
  Center,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import TKLOGO from "assets/img/layout/logoWhiteBig.png";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
// login google
import { useGoogleLogin } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
// Student Context

const SignIn = () => {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const googleBg = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.800", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  //login google
  const history = useHistory();

  const handleLogin = async (googleData) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/login-student`,
      {
        method: "POST",
        body: JSON.stringify({
          userToken: googleData.access_token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();

    if (data.status === 200) {
      Cookies.set("token", data.token);
      history.push("/student/dashboard");
    } else if (data.status === 403) {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: data?.message ?? "Something went wrong",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: data?.message ?? "Something went wrong",
      });
    }
  };

  const handleFailLogin = () => {
    Swal.fire({
      icon: "error",
      title: "Login Failed",
      text: "Something went wrong",
    });
  };

  const loginLMS = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleLogin(tokenResponse);
    },
    onError: () => handleFailLogin(),
  });

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "20px", md: "5vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={textColor}
            fontSize={{ base: "24px", md: "48px" }}
            mb="10px"
          >
            LMS TK BUDI UTOMO
          </Heading>
          <Text
            display={{ base: "none", md: "block" }}
            mb={{ base: "36px", md: "60px" }}
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Untuk Masuk ke LMS TK BUDI UTOMO, Silahkan Login Terlebih Dahulu
          </Text>
        </Box>
        <Box display={{ base: "block", md: "none" }} w="100%">
          <Center>
            <Image src={TKLOGO} alt="Logo TK Budi Utomo" />
          </Center>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={loginLMS}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Login Dengan Google
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              -
            </Text>
            <HSeparator />
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;
