import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { getALLClass } from "../../../configs/redux/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    class_id: yup.number().positive().integer().required(),
    year: yup.number().positive().integer().required(),
    month: yup.number().positive().integer().required(),
    week_number: yup.number().positive().integer().required(),
    activity: yup.string().required(),
  })
  .required();

const ClassModal = ({
  isOpen,
  handleCloseModal,
  handleSaveChanges,
  getALLClass,
  dataClass,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getALLClass();
  }, [getALLClass]);

  useEffect(() => {
    setValue("class_id", "");
    setValue("activity", "");
    setValue("year", "");
    setValue("month", "");
    setValue("week_number", "");
  }, [setValue]);

  const onSubmit = (data) => {
    handleSaveChanges(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Data</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel htmlFor="teacher_id"> Year </FormLabel>
              <Input
                type="number"
                id="year"
                {...register("year")}
                placeholder="Year"
              />
              {errors.year && (
                <Text fontSize="xs" color="tomato">
                  {errors.year.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="month"> Month</FormLabel>
              <Select name="month" id="month" {...register("month")}>
                <option value="">Select Month</option>
                <option value="1">Januari</option>
                <option value="2">Februari</option>
                <option value="3">Maret</option>
                <option value="4">April</option>
                <option value="5">Mei</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">Agustus</option>
                <option value="9">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Desember</option>
              </Select>

              {errors.month && (
                <Text fontSize="xs" color="tomato">
                  {errors.month.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="week_number"> week_number</FormLabel>
              <Select
                name="week_number"
                id="week_number"
                {...register("week_number")}
              >
                <option value="">Select week_number</option>
                <option value="1">Minggu Ke 1</option>
                <option value="2">Minggu Ke 2</option>
                <option value="3">Minggu Ke 3</option>
                <option value="4">Minggu Ke 4</option>
              </Select>

              {errors.week_number && (
                <Text fontSize="xs" color="tomato">
                  {errors.week_number.message}
                </Text>
              )}
            </FormControl>

            <FormControl mb={4}>
              <FormLabel htmlFor="class_id">Class Name</FormLabel>
              <Select
                name="class_id"
                id="class_id"
                {...register("class_id")}
              >
                <option value="">Select Class</option>
                {dataClass.map((Class) => (
                  <option key={Class.id} value={Class.id}>
                    {Class.name}
                  </option>
                ))}
              </Select>
              {errors.teacher_id && (
                <Text fontSize="xs" color="tomato">
                  {errors.teacher_id.message}
                </Text>
              )}
            </FormControl>

            <FormControl mb={4}>
              <FormLabel htmlFor="activity">Catatan</FormLabel>
              <Textarea id="activity" {...register("activity")} />
              {errors.activity && (
                <Text fontSize="xs" color="tomato">
                  {errors.activity.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const reduxState = (state) => ({
  dataClass: state.getData.dataClass.data,
  LoadingData2: state.getData.dataClass.loading,
});

const reduxDispatch = (dispatch) => {
  return {
    getALLClass: () => dispatch(getALLClass()),
  };
};

export default connect(reduxState, reduxDispatch)(ClassModal);
