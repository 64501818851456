import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getPMT,
  createPMT,
  deletePMT,
  updatePMT,
} from "../../../configs/redux/action";
import Swal from "sweetalert2";
import Card from "components/card/Card";
import PMTTable from "./FoodTable";
import PMTModal from "./FoodModal";
import FilterComponent from "../../../components/table/FilterComponent";

const PMT = ({
  getPMT,
  dataPMT,
  loadingData,
  createPMT,
  deletePMT,
  updatePMT,
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Refresh, setRefresh] = useState(0);

  const handleSaveChanges = async (id, update) => {
    console.log(id, update);
    if (id === 0) {
      const res = await createPMT(update);

      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been created.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    } else {
      const res = await updatePMT(id, update);
      if (res.status === 200) {
        Swal.fire("Success!", "Your data has been updated.", "success");
      } else {
        Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
      }
    }
    setRefresh(Refresh + 1);
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setIsModalOpen(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deletePMT(id);
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          setRefresh(Refresh + 1);
        } else {
          Swal.fire("Failed!", "Try Refresh / Contact Developer", "error");
        }
      }
    });
  };

  const handleUpdate = (id) => {
    const selectedRow = dataPMT.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setIsModalOpen(true);
  };
  const handleAddNew = () => {
    setSelectedRow(null);
    setIsModalOpen(true);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getPMT();
      console.log(Refresh);
    };
    parseUserInfo();
  }, [getPMT, Refresh]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Data PMT
          </Text>
          <Box>
            <FilterComponent
              handleSearch={handleSearch}
              onClear={handleClear}
              filterText={filterText}
              filterBy="PMT"
            />
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Button colorScheme="blue" size="sm" onClick={() => handleAddNew()}>
            Tambah PMT
          </Button>
        </Flex>
        <Center
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        ></Center>
        {loadingData && dataPMT.length > 0 ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <PMTTable
              data={dataPMT}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              resetPaginationToggle={resetPaginationToggle}
              filterText={filterText}
            />
          </>
        )}
      </Card>

      <PMTModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        handleSaveChanges={handleSaveChanges}
      />
    </Box>
  );
};

const reduxState = (state) => ({
  dataPMT: state.getData.dataPMT.data,
  loadingData: state.getData.dataPMT.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getPMT: () => dispatch(getPMT()),
    createPMT: (data) => dispatch(createPMT(data)),
    deletePMT: (id) => dispatch(deletePMT(id)),
    updatePMT: (id, data) => dispatch(updatePMT(id, data)),
  };
};

export default connect(reduxState, reduxDispatch)(PMT);
