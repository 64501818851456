// Chakra Imports
import { Box } from "@chakra-ui/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import responsiveStyle from "./daiilyStyle";
import React from "react";
import Card from "./card";

export default function CarouselSlide({ data }) {
  return (
    <Box>
      <Carousel
        additionalTransfrom={0}
        arrows
        removeArrowOnDeviceType={["tablet", "mobile"]}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        draggable
        focusOnSelect={false}
        infinite={false}
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsiveStyle}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        slidesToSlide={1}
        swipeable
      >
        {data.map((item, index) => (
          <Card
            key={index}
            date={item.date}
            activity={item.activity}
            note={item.note}
            teacher={item.teacher_name}
            isNew={index === 0}
          />
        ))}
      </Carousel>
    </Box>
  );
}
