import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const sendRequest = async (url, method, data = null) => {
  const token = await Cookies.get("token");
  const user = jwt_decode(token);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    roles: user.role,
  };

  const options = {
    method,
    headers,
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  return new Promise((resolve) => {
    fetch(url, options)
      .then((res) => res.json())
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        console.log(err);
        resolve([]);
      });
  });
};

export const tesLogin = () => async (dispatch) => {
  console.log("tes login murid");
  const token = Cookies.get("token");

  if (!token) {
    dispatch({ type: "TES_LOGIN", value: false, loading: false });
    return false;
  }

  try {
    const data = jwt_decode(token);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/cek-login-student`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          roles: data.role,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    dispatch({ type: "GET_DATA_LOGIN", value: true, loading: false });
    return true;
  } catch (err) {
    dispatch({ type: "TES_LOGIN", value: false, loading: false });
    return false;
  }
};

export const tesLoginTeacher = () => async (dispatch) => {
  console.log("tes login teacher");
  const token = Cookies.get("token");

  if (!token) {
    dispatch({ type: "TES_LOGIN", value: false, loading: false });
    return false;
  }

  try {
    const data = jwt_decode(token);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/cek-login-teacher`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          roles: data.role,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    dispatch({ type: "GET_DATA_LOGIN", value: true, loading: false });
    return true;
  } catch (err) {
    dispatch({ type: "TES_LOGIN", value: false, loading: false });
    return false;
  }
};

export const tesLoginAdmin = () => async (dispatch) => {
  const token = Cookies.get("token");

  if (!token) {
    dispatch({ type: "TES_LOGIN", value: false, loading: false });
    return false;
  }

  try {
    const data = jwt_decode(token);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/cek-login-admin`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          roles: data.role,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    dispatch({ type: "GET_DATA_LOGIN", value: true, loading: false });
    return true;
  } catch (err) {
    dispatch({ type: "TES_LOGIN", value: false, loading: false });
    return false;
  }
};
// Teacher
export const getALLTeacher = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/teacher`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_TEACHER",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updateTeacher = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/teacher/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_TEACHER",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deleteTeacher = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/teacher/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_TEACHER",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createTeacher = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/teacher`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_TEACHER",
    value: res.data,
    loading: false,
  });

  return res;
};

// Class

export const getALLClass = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/class`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_CLASS",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updateClass = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/class/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_CLASS",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deleteClass = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/class/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_CLASS",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createClass = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/class`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_CLASS",
    value: res.data,
    loading: false,
  });

  return res;
};

// Student
export const getALLStudent = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/student`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updateStudent = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/student/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deleteStudent = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/student/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createStudent = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/student`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

// PMT Food
export const getPMT = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/pmt`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_PMT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updatePMT = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/pmt/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_PMT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deletePMT = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/pmt/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_PMT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createPMT = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/pmt`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_PMT",
    value: res.data,
    loading: false,
  });

  return res;
};

// payment
export const GET_DATA_PAYMENT = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/payment`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updatePayment = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/payment/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deletePayment = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/payment/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createPayment = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/payment`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createPaymentNewStudent = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/history-payment-new-student`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createPaymentHistory = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/history-payment/${id}`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updatePaymentHistory = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/history-payment/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deletePaymentHistory = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/history-payment/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_PAYMENT",
    value: res.data,
    loading: false,
  });

  return res;
};
// daily report
export const getAllDataDaily = (page, limit, search) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/daily-report?page=${page}&per_page=${limit}&filter=${search}`,
    "GET"
  );
  dispatch({
    type: "GET_DATA_DAILY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updateDailyReport = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/daily-report/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_DAILY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deleteDailyReport = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/daily-report/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_DAILY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createDailyReport = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/daily-report`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_DAILY",
    value: res.data,
    loading: false,
  });

  return res;
};


export const createBatchDailyReport = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/daily-report-batch`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_DAILY",
    value: res.data,
    loading: false,
  });

  return res;
};
// weekly report

export const getDataWeeklyReport = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/weekly-report`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_WEEKLY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const updateWeeklyReport = (id, data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/weekly-report/${id}`,
    "PUT",
    data
  );

  dispatch({
    type: "UPDATE_DATA_WEEKLY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const deleteWeeklyReport = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/weekly-report/${id}`,
    "DELETE"
  );

  dispatch({
    type: "DELETE_DATA_WEEKLY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createWeeklyReport = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/weekly-report`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_WEEKLY",
    value: res.data,
    loading: false,
  });

  return res;
};

export const createWeeklyReportBatch = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/weekly-report-batch`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_WEEKLY",
    value: res.data,
    loading: false,
  });

  return res;
};


export const createBatchWeeklyReport = (data) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/weekly-report-batch`,
    "POST",
    data
  );

  dispatch({
    type: "CREATE_DATA_WEEKLY",
    value: res.data,
    loading: false,
  });

  return res;
};
// get data by student id
export const getStudentbyId = (id) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/student/${id}`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_BY_STUDENT_ID",
    value: res.data,
    loading: false,
  });

  return res;
};

export const getPaymentStudent = () => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/payment-student/`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_PAYMENT_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const getDailyStudent = (id) => async (dispatch) => {
  const url = id
    ? `${process.env.REACT_APP_API_URL}/api/daily-report-student/${id}`
    : `${process.env.REACT_APP_API_URL}/api/daily-report-student`;

  const res = await sendRequest(url, "GET");

  dispatch({
    type: "GET_DATA_DAILY_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const getWeeklyStudent = (id) => async (dispatch) => {
  const url = id
    ? `${process.env.REACT_APP_API_URL}/api/weekly-report-student/${id}`
    : `${process.env.REACT_APP_API_URL}/api/weekly-report-student`;

  const res = await sendRequest(url, "GET");

  dispatch({
    type: "GET_DATA_WEEKLY_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

// pmt by year
export const getPMTByYear = (year) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/pmt-year/${year}`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_PMT_YEAR",
    value: res.data,
    loading: false,
  });

  return res;
};

// dashboard

export const getDashboardStudent = (year) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/student-dashboard`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_DASHBOARD_STUDENT",
    value: res.data,
    loading: false,
  });

  return res;
};

export const getDashboardTeacher = (year) => async (dispatch) => {
  const res = await sendRequest(
    `${process.env.REACT_APP_API_URL}/api/teacher-dashboard/${year}`,
    "GET"
  );

  dispatch({
    type: "GET_DATA_DASHBOARD_TEACHER",
    value: res.data,
    loading: false,
  });

  return res;
};
