import React from "react";
import DataTable from "react-data-table-component";
import { Button } from "@chakra-ui/react";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";

const ClassTable = ({
  data,
  handleDelete,
  handleUpdate,
  resetPaginationToggle,
  filterText,
}) => {
  console.log(data);
  const columns = [
    {
      name: "Tahun",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "Bulan",
      selector: (row) => row.month,
      sortable: true,
    },
    {
      name: "PMT",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button colorScheme="red" mr={2} onClick={() => handleDelete(row.id)}>
            <RiDeleteBinLine />
          </Button>
          <Button colorScheme="blue" onClick={() => handleUpdate(row.id)}>
            <RiEditLine />
          </Button>
        </div>
      ),
    },
  ];

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.note &&
          item.note.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  return (
    <div className="p-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortFieldId={1}
      />
    </div>
  );
};

export default ClassTable;
