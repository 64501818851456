import React from "react";
import { Box, Badge, VStack, Heading, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from "moment";
import "moment/locale/id";

export default function card({ isNew, activity, note, teacher, date }) {
  function ChildContent({ title, desc, ...rest }) {
    return (
      <Box p={5} mx={2} shadow="md" borderWidth="1px" {...rest} w="100%">
        <Heading fontSize="md">{title}</Heading>
        <Text mt={4} textAlign="justify">
          {desc}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      mx="10px"
    >
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <VStack spacing={2}>
          <Box p="6">
            <Box display="flex" alignItems="baseline">
              {isNew ? (
                <Badge borderRadius="full" px="2" colorScheme="teal">
                  Terbaru
                </Badge>
              ) : null}
              <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                ml="2"
              >
                {moment(date).format("dddd")} &bull;{" "}
                {moment(date).format("D MMMM YYYY")}
              </Box>
            </Box>
          </Box>

          <ChildContent title="Aktivitas" desc={activity} />
          <ChildContent title="Catatan" desc={note} />
          <Box w="100%" align="right" px="6">
            <Text fontSize="xs">Pendidik : {teacher}</Text>
          </Box>
        </VStack>
      </Card>
    </Box>
  );
}
