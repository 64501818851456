import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { getALLClass } from "../../../configs/redux/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import formatDate from "components/calendar/FormatDate";
const schema = yup
  .object({
    name: yup.string().required(),
    parent_name: yup.string().required(),
    birth_date: yup.date().required(),
    birth_place: yup.string().required(),
    gender: yup.string().required(),
    address: yup.string().required(),
    phone_number: yup.number().required(),
    email: yup.string().email().required(),
    is_active: yup.boolean().required(),
    year_of_entry: yup.number().positive().integer().required(),
    year_of_graduation: yup.number().positive().integer().required(),
    note: yup.string().required(),
    class_id: yup.number().positive().integer().required(),
    nis: yup.number().positive().integer().required(),
  })
  .required();

const StudentModal = ({
  isOpen,
  handleCloseModal,
  selectedRow,
  handleSaveChanges,
  getALLClass,
  dataClass,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getALLClass();
  }, [getALLClass]);

  useEffect(() => {
    if (selectedRow) {
      setValue("name", selectedRow.name);
      setValue("parent_name", selectedRow.parent_name);
      setValue("birth_date", formatDate(selectedRow.birth_date));
      setValue("birth_place", selectedRow.birth_place);
      setValue("gender", selectedRow.gender);
      setValue("address", selectedRow.address);
      setValue("phone_number", selectedRow.phone_number);
      setValue("email", selectedRow.email);
      setValue("is_active", selectedRow.is_active);
      setValue("year_of_entry", selectedRow.year_of_entry);
      setValue("year_of_graduation", selectedRow.year_of_graduation);
      setValue("note", selectedRow.note);
      setValue("class_id", selectedRow.class_id);
      setValue("nis", selectedRow.nis);
    } else {
      setValue("name", "");
      setValue("parent_name", "");
      setValue("birth_date", "");
      setValue("birth_place", "");
      setValue("gender", "");
      setValue("address", "");
      setValue("phone_number", "");
      setValue("email", "");
      setValue("is_active", "");
      setValue("year_of_entry", "");
      setValue("year_of_graduation", "");
      setValue("note", "");
      setValue("class_id", "");
      setValue("nis", "");
    }
  }, [selectedRow, setValue]);

  const onSubmit = (data) => {
    let id = selectedRow?.id || 0;
    handleSaveChanges(id, data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Data</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel htmlFor="nis">NIS</FormLabel>
              <Input type="number" id="nis" {...register("nis")} />
              {errors.nis && (
                <Text fontSize="xs" color="tomato">
                  {errors.nis.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input type="text" id="name" {...register("name")} />
              {errors.name && (
                <Text fontSize="xs" color="tomato">
                  {errors.name.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="class_id">Class</FormLabel>
              <Select name="class_id" id="class_id" {...register("class_id")}>
                <option value="">Select Class</option>
                {dataClass.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
              {errors.class_id && (
                <Text fontSize="xs" color="tomato">
                  {errors.class_id.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="parent_name">Parent Name</FormLabel>
              <Input
                type="text"
                id="parent_name"
                {...register("parent_name")}
              />
              {errors.parent_name && (
                <Text fontSize="xs" color="tomato">
                  {errors.parent_name.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="birth_date">Birth Date</FormLabel>
              <Input type="date" id="birth_date" {...register("birth_date")} />
              {errors.birth_date && (
                <Text fontSize="xs" color="tomato">
                  {errors.birth_date.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="birth_place">Birth Place</FormLabel>
              <Input
                type="text"
                id="birth_place"
                {...register("birth_place")}
              />
              {errors.birth_place && (
                <Text fontSize="xs" color="tomato">
                  {errors.birth_place.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="gender">Gender</FormLabel>
              <Select name="gender" id="gender" {...register("gender")}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Select>
              {errors.gender && (
                <Text fontSize="xs" color="tomato">
                  {errors.gender.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="address">Address</FormLabel>
              <Textarea id="address" {...register("address")} />
              {errors.address && (
                <Text fontSize="xs" color="tomato">
                  {errors.address.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="phone_number">Phone Number</FormLabel>
              <Input
                type="number"
                id="phone_number"
                {...register("phone_number")}
              />
              {errors.phone_number && (
                <Text fontSize="xs" color="tomato">
                  {errors.phone_number.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input type="email" id="email" {...register("email")} />
              {errors.email && (
                <Text fontSize="xs" color="tomato">
                  {errors.email.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="is_active">Is Active</FormLabel>
              <Select
                name="is_active"
                id="is_active"
                {...register("is_active")}
              >
                <option value="">Select Activity Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Select>
              {errors.is_active && (
                <Text fontSize="xs" color="tomato">
                  {errors.is_active.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="year_of_entry">Year of Entry</FormLabel>
              <Input
                type="number"
                id="year_of_entry"
                {...register("year_of_entry")}
              />
              {errors.year_of_entry && (
                <Text fontSize="xs" color="tomato">
                  {errors.year_of_entry.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="year_of_graduation">
                Year of Graduation
              </FormLabel>
              <Input
                type="number"
                id="year_of_graduation"
                {...register("year_of_graduation")}
              />
              {errors.year_of_graduation && (
                <Text fontSize="xs" color="tomato">
                  {errors.year_of_graduation.message}
                </Text>
              )}
            </FormControl>
            <FormControl mb={4}>
              <FormLabel htmlFor="note">Note</FormLabel>
              <Textarea id="note" {...register("note")} />
              {errors.note && (
                <Text fontSize="xs" color="tomato">
                  {errors.note.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save Changes
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const reduxState = (state) => ({
  dataClass: state.getData.dataClass.data,
  loadingData: state.getData.dataClass.loading,
});

const reduxDispatch = (dispatch) => {
  return {
    getALLClass: () => dispatch(getALLClass()),
  };
};

export default connect(reduxState, reduxDispatch)(StudentModal);
