// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect } from "react";
import { getDashboardTeacher } from "../../../configs/redux/action";
import { connect } from "react-redux";
import moneyFormat from "components/dataDispaly/moneyFormat";

import {
  MdSupervisedUserCircle,
  MdAttachMoney,
  MdMoneyOffCsred,
} from "react-icons/md";

const moment = require("moment");

const TeacherDashboard = ({
  getDashboardTeacher,
  dataDashboardTeacher,
  loadingData,
}) => {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const currentYear = moment().format("YYYY");

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getDashboardTeacher(currentYear);
    };
    parseUserInfo();
  }, [getDashboardTeacher]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!loadingData ? (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdSupervisedUserCircle}
                    color={brandColor}
                  />
                }
              />
            }
            name="Jumlah Murid Aktif"
            value={dataDashboardTeacher.totalActiveStudents}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAttachMoney}
                    color={brandColor}
                  />
                }
              />
            }
            name="Jumlah Pembayaran Masuk"
            value={moneyFormat(dataDashboardTeacher.studentPaymentsRP)}
          />

          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdMoneyOffCsred}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Pembayaran Belum Lunas"
            value={moneyFormat(dataDashboardTeacher.unpaidPaymentsRP)}
          />
        </SimpleGrid>
      ) : (
        <>Loading</>
      )}

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <MiniCalendar h="100%" minW="100%" selectRange={false} />
      </SimpleGrid>
    </Box>
  );
};

const reduxState = (state) => ({
  dataDashboardTeacher: state.getData.dataDashboardTeacher.data,
  loadingData: state.getData.dataDashboardTeacher.loading,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getDashboardTeacher: (currentYear) =>
      dispatch(getDashboardTeacher(currentYear)),
  };
};

export default connect(reduxState, reduxDispatch)(TeacherDashboard);
