import React from "react";
import DataTable from "react-data-table-component";
import { Button } from "@chakra-ui/react";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";

const TeacherTable = ({
  data,
  handleDelete,
  handleUpdate,
  resetPaginationToggle,
  filterText,
}) => {
  const columns = [
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "phone_number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button colorScheme="red" mr={2} onClick={() => handleDelete(row.id)}>
            <RiDeleteBinLine />
          </Button>
          <Button colorScheme="blue" onClick={() => handleUpdate(row.id)}>
            <RiEditLine />
          </Button>
        </div>
      ),
    },
  ];

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  return (
    <div className="p-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        selectableRows
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortFieldId={1}
      />
    </div>
  );
};

export default TeacherTable;
