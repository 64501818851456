import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  useColorModeValue,
  Text,
  Spinner,
  Center,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import React, { useEffect } from "react";
import { getPMTByYear } from "../../../configs/redux/action";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/id";

const FoodReport = ({ getPMTByYear, dataPMTYear, loadingData }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const currentDate = moment();
  const currentYear = currentDate.format("YYYY");

  function numberMonthToText(number) {
    return moment(number, "MM").format("MMMM");
  }

  useEffect(() => {
    const parseUserInfo = async () => {
      const res = await getPMTByYear(currentYear);
    };
    parseUserInfo();
  }, [getPMTByYear]);

  return (
    <Box mt={{ base: "130px", md: "100px", xl: "80px" }}>
      <Card w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Laporan PMT {currentYear}
          </Text>
        </Flex>
        {loadingData ? (
          <Center mt="70px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <Accordion className="w-full" allowMultiple>
            {dataPMTYear.map((item, index) => (
              <AccordionItem
                key={index}
                className="border-b border-gray-200 py-[17px] dark:!border-white/10"
              >
                <h2>
                  <AccordionButton className="flex justify-between">
                    <span
                      className="text-left font-bold text-navy-900 dark:text-white"
                      flex="1"
                      textalign="left"
                    >
                      {numberMonthToText(item.month)}
                    </span>
                    <AccordionIcon className="text-left !text-navy-900 dark:!text-white" />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  className="text-left text-medium mt-2 !text-navy-900 dark:!text-white"
                  pb={4}
                >
                  {item.note}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Card>
    </Box>
  );
};

const reduxState = (state) => ({
  dataPMTYear: state.getData.dataPMTYear.data,
  loadingData: state.getData.dataPMTYear.loadingData,
});

const reduxDispatch = () => (dispatch) => {
  return {
    getPMTByYear: (year) => dispatch(getPMTByYear(year)),
  };
};

export default connect(reduxState, reduxDispatch)(FoodReport);
