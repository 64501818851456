import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import "moment/locale/id";

const DailyTable = ({ data, resetPaginationToggle, filterText }) => {
  const columns = [
    {
      name: "Siswa",
      selector: (row) => row.nis + " | " + row.name,
      sortable: true,
    },
    {
      name: "Item",
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row.status_payment,
      sortable: true,
    },
    {
      name: "Total Pembayaran",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Pembayaran Masuk",
      selector: (row) => row.paid,
      sortable: true,
    },
    {
      name: "Sisa Pembayaran",
      selector: (row) => row.notpaid,
      sortable: true,
    },
  ];

  const filteredItems = data
    ? data.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    : [];

  const subTableColumns = [
    {
      name: "History Pembayaran",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Nominal Pembayaran",
      selector: (row) => row.amount,
      // sortable: true,
    },
    {
      name: "Tanggal Pembayaran",
      selector: (row) => row.date_paid,
      sortable: true,
    },
  ];

  const SubTableComponent = ({ data }) => {
    const expandedRows = data.history_payments_json.map((row) => ({
      name: row.name,
      amount: row.amount,
      date_paid: row.date_paid
        ? moment(row.date_paid).format("DD-MMMM-YYYY")
        : "-",
    }));
    return (
      <>
        <DataTable
          columns={subTableColumns}
          data={expandedRows}
          noHeader
          pagination={false}
        />
      </>
    );
  };

  return (
    <div className="p-2">
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        persistTableHead
        paginationResetDefaultPage={resetPaginationToggle}
        defaultSortFieldId={1}
        expandableRows
        expandableRowsComponent={SubTableComponent}
      />
    </div>
  );
};

export default DailyTable;
